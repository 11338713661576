import { SplitClient, SplitFactoryProvider } from '@splitsoftware/splitio-react'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import trackImpression from './trackImpressions'
import { ORG_DATA_QUERY } from './queries/currentOrganization'
import { LOCATION_DATA_QUERY } from './queries/location'
import getEnvironmentFromURL from './getEnvironmentFromURL'
import getAttributesFromAccount from './getAttributesFromAccount'

import type { OrganizationAttributes, OrgChangeEvent } from './types'

export const SPLITIO_PROD_CLIENT_KEY = 'p80t4a0uumurkoiu03n901vds197chq8bssp'
export const SPLITIO_LOCAL_CLIENT_KEY = 'cv7k6ubsjas041tvs0869rd69l5i26svbg8r'
export const SPLITIO_STAGING_CLIENT_KEY = '7ep659kbp3peet2964pukms48g4ubsn83b9s'

/** @todo Ideally pull this from the window.appshell global. Couldn't do it here due to race condition. */
const ORG_CHANGE_EVENT = 'appshell__organization_event'

export const OrganizationAttributeContext =
  React.createContext<OrganizationAttributes | null>(null)

export const IsImpersonationContext = React.createContext<boolean>(false)

export function FeaturesWrapper({
  mockSplits,
  children,
  environment: environmentProp,
}: {
  mockSplits?: SplitIO.MockedFeaturesMap
  children: React.ReactNode
  environment?: string
}) {
  // Use no-cache to see if it helps avoid conflicts with other Apollo clients
  const { data } = useQuery(ORG_DATA_QUERY, { fetchPolicy: 'no-cache' })
  const { data: locationData } = useQuery(LOCATION_DATA_QUERY)
  const [splitFactory, setSplitFactory] =
    useState<SplitIO.IBrowserSettings | null>(null)

  const initialOrgId = data?.account?.currentOrganization?.id
  const [switchedOrgId, setSwitchedOrgId] = useState<string | null>(null)
  const currentOrgId = switchedOrgId || initialOrgId
  const [organizationAttributes, setOrganizationAttributes] =
    useState<OrganizationAttributes | null>(null)

  useEffect(() => {
    setOrganizationAttributes(
      getAttributesFromAccount(data?.account, locationData),
    )
  }, [currentOrgId, data, locationData])

  useEffect(() => {
    function handleOrgSwitch(event: OrgChangeEvent) {
      setSwitchedOrgId(event.detail.organizationId)
    }
    window.addEventListener(ORG_CHANGE_EVENT, handleOrgSwitch as EventListener)
    return function cleanup() {
      window.removeEventListener(
        ORG_CHANGE_EVENT,
        handleOrgSwitch as EventListener,
      )
    }
  }, [])

  useEffect(() => {
    if (splitFactory || !currentOrgId) return

    const factory: SplitIO.IBrowserSettings = {
      core: {
        authorizationKey: SPLITIO_PROD_CLIENT_KEY,
        key: currentOrgId,
        trafficType: 'organization',
      },

      // Track experiments to Segment
      impressionListener: {
        logImpression: trackImpression,
      },
    }

    // Support mock / localhost mode
    // https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#localhost-mode
    if (mockSplits) {
      factory.core.authorizationKey = 'localhost'
      factory.features = mockSplits
    } else {
      // Support environments (either forced by prop, or detected)
      const environment = environmentProp ?? getEnvironmentFromURL()

      if (environment === 'staging') {
        factory.core.authorizationKey = SPLITIO_STAGING_CLIENT_KEY
      }
      if (environment === 'local') {
        factory.core.authorizationKey = SPLITIO_LOCAL_CLIENT_KEY
      }
      if (environment === 'production') {
        factory.core.authorizationKey = SPLITIO_PROD_CLIENT_KEY
      }
    }

    setSplitFactory(factory)
  }, [currentOrgId, environmentProp, mockSplits, splitFactory])

  if (!splitFactory) {
    return <>{children}</>
  }

  return (
    <SplitFactoryProvider config={splitFactory}>
      <SplitClient splitKey={currentOrgId} trafficType="organization">
        <IsImpersonationContext.Provider
          value={data?.account?.isImpersonation ?? false}
        >
          <OrganizationAttributeContext.Provider value={organizationAttributes}>
            {children}
          </OrganizationAttributeContext.Provider>
        </IsImpersonationContext.Provider>
      </SplitClient>
    </SplitFactoryProvider>
  )
}
