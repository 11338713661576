import { type SetStateAction, useCallback, useEffect, useState } from 'react'

/**
 * Reads a value from localStorage and parses it from JSON.
 *
 * @template T - The type of value being stored
 * @param {string} key - The localStorage key to read from
 * @param {T} defaultValue - The default value to return if the key doesn't exist or parsing fails
 * @returns {T} The parsed value from localStorage or the default value
 *
 * @example
 * const value = readLocalStorageValue('user-settings', { theme: 'light' });
 */
function readLocalStorageValue<T>(key: string, defaultValue: T): T {
  try {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : defaultValue
  } catch (error) {
    return defaultValue
  }
}

/**
 * Writes a value to localStorage after stringifying it to JSON.
 * Also dispatches a storage event to notify other tabs/windows of the change.
 *
 * @template T - The type of value being stored
 * @param {string} key - The localStorage key to write to
 * @param {T} value - The value to store
 * @throws {Error} If localStorage is full or writing is not permitted
 *
 * @example
 * writeLocalStorageValue('user-settings', { theme: 'dark' });
 */
function writeLocalStorageValue<T>(key: string, value: T): void {
  const stringifiedValue = JSON.stringify(value)

  window.localStorage.setItem(key, stringifiedValue)

  // We dispatch a custom event so every similar useLocalStorage hook is notified
  const evt = new StorageEvent('local-storage', { key })
  window.dispatchEvent(evt)
}

/**
 * A React hook that syncs state with localStorage and keeps it in sync across tabs/windows.
 * The value is automatically serialized to JSON when storing and deserialized when reading.
 *
 * @template T - The type of value being stored
 * @param {string} key - The localStorage key to use
 * @param {T} defaultValue - The initial value to use if nothing is stored
 * @returns {[T, (value: SetStateAction<T>) => void]} A tuple containing the current value and a setter function
 *
 * @example
 * ```tsx
 * // Simple usage with a string
 * const [name, setName] = useLocalStorage('user-name', 'John');
 *
 * // Usage with an object
 * const [settings, setSettings] = useLocalStorage('user-settings', {
 *   theme: 'light',
 *   notifications: true
 * });
 *
 * // Using the setter function with previous value
 * setSettings(prev => ({ ...prev, theme: 'dark' }));
 * ```
 *
 * @throws {Error} If localStorage is not available or if JSON parsing/stringifying fails
 *
 * @remarks
 * - The hook will automatically sync state across tabs/windows using the 'storage' event
 * - The value is persisted even after page refresh
 * - The setter function accepts both direct values and updater functions
 * - If localStorage is not available or errors occur, it falls back to using the default value
 */
export function useLocalStorage<T>(
  key: string,
  defaultValue: T,
): [T, (value: SetStateAction<T>) => void] {
  const [value, setValue] = useState<T>(() =>
    readLocalStorageValue(key, defaultValue),
  )
  const handleStorageChange = useCallback(
    (event: StorageEvent | Event): void => {
      if ((event as StorageEvent).key && (event as StorageEvent).key !== key) {
        return
      }

      setValue(readLocalStorageValue(key, defaultValue))
    },
    [key, defaultValue],
  )

  useEffect(() => {
    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange)

    // this is a custom event, triggered in writeValueToLocalStorage
    // See: useLocalStorage()
    window.addEventListener('local-storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
      window.removeEventListener('local-storage', handleStorageChange)
    }
  }, [key, defaultValue, handleStorageChange])

  const setValueInLocalStorage = useCallback(
    (value: SetStateAction<T>): void => {
      setValue(() => {
        const updatedValue: T =
          typeof value === 'function'
            ? (value as (prevState: T) => T)(
                // refresh with latest value in case another hook or tab has updated it
                // this will most closely emulate the behavior of useState, where
                // the latest value is used in the hook even if multiple updates
                // occur in a single render cycle
                readLocalStorageValue(key, defaultValue),
              )
            : (value as T)

        try {
          writeLocalStorageValue(key, updatedValue)
        } catch (error) {
          // Ignore error, we should log it somewhere
          console.error(error)
        }

        return updatedValue
      })
    },
    [key, defaultValue],
  )

  return [value, setValueInLocalStorage]
}
