import type { LucideIcon } from 'lucide-react'
// This file was generated by svgr, do not edit it.
import * as React from 'react'
import clsx from 'clsx'
import styles from '../Icon.module.css'
import textColorStyles from '../../../styles/atoms/text-color.module.css'
import {
  IconProps,
  computeStrokeWidth,
  computeSize,
  IconComponentRef,
} from '../helpers'

/**
 * Creates a wrapped Lucide icon component that follows Buffer's icon conventions.
 * The wrapped component inherits Buffer's styling system and supports standard icon props.
 *
 * @param {LucideIcon} IconComponent - The Lucide icon component to wrap
 * @param {string} displayName - The display name for the wrapped component (used in React DevTools)
 * @returns {React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>} A wrapped icon component with Buffer's styling
 */
export const makeLucideIcon = (
  IconComponent: LucideIcon,
  displayName: string,
) => {
  const WrappedIcon = React.forwardRef(
    (
      { size, stroke, className, color, ...props }: IconProps,
      ref: IconComponentRef,
    ) => {
      const computedSize = computeSize(size)
      const computedStrokeWidth = computeStrokeWidth(computedSize, stroke)
      return (
        <IconComponent
          ref={ref}
          data-icon={displayName}
          strokeWidth={computedStrokeWidth}
          width={computedSize}
          height={computedSize}
          aria-hidden="true"
          className={clsx(
            styles.base,
            color && textColorStyles[color],
            className,
          )}
          {...props}
        />
      )
    },
  )
  WrappedIcon.displayName = displayName
  return WrappedIcon
}
