import * as React from 'react'
import clsx from 'clsx'
import * as DialogPrimitive from '@radix-ui/react-dialog'

import usePortalContainer from '../../helpers/usePortalContainer'
import { UnstyledButton } from '../UnstyledButton'
import { CloseIcon } from '../icons'
import { Heading } from '../Heading'
import { Paragraph, ParagraphProps } from '../Paragraph'
import { VisuallyHidden } from '../VisuallyHidden'

import styles from './Dialog.module.css'

/**
 * Dialog Provider
 */
type DialogProps = React.ComponentPropsWithoutRef<'div'> & {
  /**
   * Whether the dialog is open or not
   */
  open?: boolean
  /**
   * The modality of the dialog. When set to true, interaction with outside
   * elements will be disabled and only dialog content will be visible to
   * screen readers.
   */
  modal?: boolean
  /**
   * Callback to be called when the dialog is closed via clicking on the
   * overlay, pressing the escape key or close button
   */
  onOpenChange?: (open: boolean) => void
  /**
   * Content to render inside the dialog
   */
  children?: React.ReactNode
}

/**
 * Dialog component displays a modal dialog window as an overlay
 */
const Dialog: React.FC<DialogProps> = (props) => {
  return <DialogPrimitive.Root {...props} />
}

Dialog.displayName = 'Dialog'

/**
 * Dialog Portal
 */
type DialogPortalProps = React.ComponentPropsWithoutRef<
  typeof DialogPrimitive.Portal
>

const DialogPortal = ({
  children,
  ...props
}: DialogPortalProps): JSX.Element => {
  const container = usePortalContainer()
  return (
    <DialogPrimitive.Portal {...props} container={container}>
      {children}
    </DialogPrimitive.Portal>
  )
}

DialogPortal.displayName = 'Dialog.Portal'

/**
 * Dialog Content
 */
type DialogContentProps = React.ComponentPropsWithoutRef<
  typeof DialogPrimitive.Content
> & {
  /**
   * Additional CSS class to apply to the viewport
   */
  className?: string
  /**
   * Content to render inside the dialog
   */
  children?: React.ReactNode
  /**
   * Preset sizes for dialog window, you can set more specific size via custom CSS
   * @default medium
   */
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'maximize'
  /**
   * Controls whether the dialog content is wrapped in a portal
   * TODO: This needs to be replaced with manual portal management
   * and is a temporary solution due to the amount of components
   * already implementing this component with default portal
   * @default true
   */
  portal?: boolean
}

type DialogContentElement = React.ElementRef<typeof DialogPrimitive.Content>

const DialogContent = React.forwardRef<
  DialogContentElement,
  DialogContentProps
>(
  (
    { className, size = 'medium', portal = true, children, ...props },
    forwardedRef,
  ) => {
    if (portal) {
      return (
        <DialogPortal>
          <DialogPrimitive.Overlay className={styles.overlay}>
            <DialogPrimitive.Content
              {...props}
              ref={forwardedRef}
              className={clsx(styles.content, size && styles[size], className)}
            >
              {children}
            </DialogPrimitive.Content>
          </DialogPrimitive.Overlay>
        </DialogPortal>
      )
    }

    return (
      <DialogPrimitive.Overlay className={styles.overlay}>
        <DialogPrimitive.Content
          {...props}
          ref={forwardedRef}
          className={clsx(styles.content, size && styles[size], className)}
        >
          {children}
        </DialogPrimitive.Content>
      </DialogPrimitive.Overlay>
    )
  },
)
DialogContent.displayName = 'Dialog.Content'

/**
 * Dialog Trigger
 */
type DialogTriggerProps = Omit<
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Trigger>,
  'asChild'
>

const DialogTrigger = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Trigger>,
  DialogTriggerProps
>((props, forwardedRef) => (
  <DialogPrimitive.Trigger {...props} ref={forwardedRef} asChild />
))

DialogTrigger.displayName = 'Dialog.Trigger'

/**
 * Dialog Title
 */
type DialogTitleProps = React.ComponentPropsWithoutRef<'h3'>
type DialogTitleElement = React.ElementRef<'h3'>

const DialogTitle = React.forwardRef<DialogTitleElement, DialogTitleProps>(
  ({ className, children, ...props }, forwardedRef) => (
    <DialogPrimitive.Title asChild>
      <Heading
        as="h3"
        size="medium"
        className={clsx(styles.title, className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </Heading>
    </DialogPrimitive.Title>
  ),
)
DialogTitle.displayName = 'Dialog.Title'

/**
 * Dialog Description
 */
type DialogDescriptionProps = ParagraphProps
type DialogDescriptionElement = React.ElementRef<'p'>

const DialogDescription = React.forwardRef<
  DialogDescriptionElement,
  DialogDescriptionProps
>(({ className, children, ...props }, forwardedRef) => (
  <DialogPrimitive.Description asChild>
    <Paragraph
      className={clsx(styles.title, className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Paragraph>
  </DialogPrimitive.Description>
))

DialogDescription.displayName = 'Dialog.Description'

/**
 * Dialog Header
 */
type DialogHeaderProps = React.ComponentPropsWithoutRef<'header'>
type DialogHeaderElement = React.ElementRef<'header'>

const DialogHeader = React.forwardRef<DialogHeaderElement, DialogHeaderProps>(
  ({ className, children, ...props }, forwardedRef) => (
    <header
      className={clsx(styles.header, className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </header>
  ),
)

DialogHeader.displayName = 'Dialog.Header'

/**
 * Dialog Body
 */
const DialogBody = React.forwardRef<
  React.ElementRef<'section'>,
  React.ComponentPropsWithoutRef<'section'>
>(({ className, children, ...props }, forwardedRef) => (
  <section
    {...props}
    className={clsx(styles.body, className)}
    ref={forwardedRef}
  >
    {children}
  </section>
))

DialogBody.displayName = 'Dialog.Body'

/**
 * Dialog Footer
 */
const DialogFooter = React.forwardRef<
  React.ElementRef<'footer'>,
  React.ComponentPropsWithoutRef<'footer'>
>(({ className, children, ...props }, forwardedRef) => (
  <footer
    {...props}
    className={clsx(styles.footer, className)}
    ref={forwardedRef}
  >
    {children}
  </footer>
))

DialogFooter.displayName = 'Dialog.Footer'

/**
 * Dialog Separator
 */
type DialogSeparatorProps = React.ComponentPropsWithoutRef<'div'> & {
  children?: never
}

const DialogSeparator = React.forwardRef<
  React.ElementRef<'div'>,
  DialogSeparatorProps
>(({ className, ...props }, forwardedRef) => (
  <div
    {...props}
    className={clsx(styles.separator, className)}
    ref={forwardedRef}
  />
))

DialogSeparator.displayName = 'Dialog.Separator'

const DialogClose = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Close>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>((props, forwardedRef) => (
  <DialogPrimitive.Close {...props} ref={forwardedRef} asChild>
    {props.children}
  </DialogPrimitive.Close>
))

DialogClose.displayName = 'Dialog.Close'

const DialogCloseButon = React.forwardRef<
  React.ElementRef<typeof UnstyledButton>,
  React.ComponentPropsWithoutRef<typeof UnstyledButton>
>((props, forwardedRef) => (
  <DialogPrimitive.Close asChild>
    <UnstyledButton
      {...props}
      ref={forwardedRef}
      className={clsx(styles.close, props.className)}
    >
      <CloseIcon />
      <VisuallyHidden>Close</VisuallyHidden>
    </UnstyledButton>
  </DialogPrimitive.Close>
))

DialogCloseButon.displayName = 'Dialog.CloseButon'

const DialogObject = Object.assign(Dialog, {
  Trigger: DialogTrigger,
  Header: DialogHeader,
  Title: DialogTitle,
  Description: DialogDescription,
  Body: DialogBody,
  Content: DialogContent,
  Portal: DialogPortal,
  Separator: DialogSeparator,
  Footer: DialogFooter,
  Close: DialogClose,
  CloseButton: DialogCloseButon,
})

export { DialogObject as Dialog }
export type {
  DialogProps,
  DialogHeaderProps,
  DialogSeparatorProps,
  DialogTitleProps,
  DialogDescriptionProps,
  DialogContentProps,
  DialogPortalProps,
}
