import { OrganizationAttributes } from './types'

/**
 * We should have somewhere to share common types for these objects below, but
 * for now I'll just define the minimum need here.
 */

type OBSub = {
  plan: {
    id: string
    name: string
  }
  trial: {
    isActive: boolean
  }
}

type MPSub = {
  plan: string
  product: string
  trial: {
    isActive: boolean
  }
}

type Organization = {
  billing: {
    subscription: OBSub
    subscriptions: MPSub[]
    hasNeverBeenPayingCustomer: boolean
    canStartTrial: boolean
  }
  isOneBufferOrganization: boolean
  createdAt: string
}

type Account = {
  isImpersonation: boolean
  currentOrganization: Organization
}

export function isOnBufferTrial({
  currentOrganization,
}: {
  currentOrganization: Organization
}) {
  return currentOrganization?.billing?.subscription?.trial?.isActive || false
}

function isFreePlan(accountData: Account) {
  const { currentOrganization } = accountData
  if (currentOrganization?.billing?.subscriptions?.length > 0) {
    return !!currentOrganization?.billing?.subscriptions?.find(
      (sub) => sub.product === 'publish' && sub.plan === 'free',
    )
  }

  return (
    !isOnBufferTrial(accountData) &&
    currentOrganization?.billing?.subscription?.plan.id === 'free'
  )
}

function isNewBuffer(accountData: Account) {
  const { currentOrganization } = accountData
  return currentOrganization?.isOneBufferOrganization || false
}

function hasNeverBeenPayingCustomer(accountData: Account) {
  const { currentOrganization } = accountData
  return currentOrganization?.billing?.hasNeverBeenPayingCustomer || false
}

function canStartTrial(accountData: Account) {
  const { currentOrganization } = accountData
  return currentOrganization?.billing?.canStartTrial || false
}

function signUpDate(accountData: Account) {
  const { currentOrganization } = accountData
  return currentOrganization?.createdAt
    ? new Date(currentOrganization?.createdAt).getTime()
    : undefined
}

function isImpersonation(accountData: Account) {
  const { isImpersonation } = accountData
  return isImpersonation || false
}

export default function getAttributesFromAccount(
  accountData: Account,
  locationData = {
    location: {
      country: null,
    },
  },
): OrganizationAttributes | null {
  if (!accountData) {
    return null
  }
  return {
    isFreePlan: isFreePlan(accountData),
    isOnTrial: isOnBufferTrial(accountData),
    isImpersonation: isImpersonation(accountData),
    isNewBuffer: isNewBuffer(accountData),
    location: locationData?.location?.country
      ? locationData.location.country
      : '',
    hasNeverBeenPayingCustomer: hasNeverBeenPayingCustomer(accountData),
    canStartTrial: canStartTrial(accountData),
    signUpDate: signUpDate(accountData),
  }
}
